@import "../../../style.scss";
.leftItemHeader h2.title {
    cursor: pointer;
}

div.searchWord.classified {
    width: ptr(400);
    @extend .d-flex,
    .align-items-center;
    padding: 0;
    .keyword {
        border-color: #969696;
    }
    button {
        top: ptr(11);
    }
    .icon-search {
        font-weight: bold;
    }
    @media (max-width: 1024px) {
        display: none !important;
    }
}