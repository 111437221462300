@import "../../../style.scss";
.principalMenu {
    .last_letter {
        text-transform: none;
        display: contents; /*Remove in V2*/
    }
    .links_beta {
        text-decoration: underline;
    }
    .petit {
        font-size: ptr(10);
    }
    .special {
        .icon-program-menu {
            font-size: ptr(15) !important;
            margin: ptr(5) ptr(10) ptr(2) 0;
        }
        .nameProgram {
            .name {
                text-align: center;
            }
            .icon-sparow {
                margin-left: ptr(5);
            }
        }
    }
}

.popPers {
    .modal-dialog {
        width: ptr(900) !important;
    }
    .headerpermission {
        background: #e5e5e5 !important;
        border: none !important;
        color: rgba(0, 0, 0, 0.4) !important;
        .title {
            text-align: center !important;
            margin-right: ptr(40);
        }
        .close {
            color: rgba(0, 0, 0, 0.4) !important;
            border-color: rgba(0, 0, 0, 0.4) !important;
        }
    }
    .modal-body {
        p {
            text-align: left !important;
            padding: ptr(50);
        }
        a {
            text-decoration: underline !important;
        }
    }
}

.linkDisable {
    cursor: no-drop;
}