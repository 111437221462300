@import "../../../style.scss";
.firstBlock.block {
    border: none;
    padding-top: 0;
}

.infoUnlocked {
    .infoEvent {
        div[class *="Event"] {
            color: $gris;
            padding-left: ptr(35);
            margin-right: ptr(25);
            font-weight: normal;
        }
    }
    .txtLabel {
        padding-left: ptr(20);
        .topic {
            background-color: #e8e8e8;
            color: #444;
            cursor: pointer;
        }
    }
}

.listEvents {
    li {
        background-color: #fff;
        @extend .flex-wrap;
        .infoEventContainer {
            width: 100%;
            padding: ptr(20);
            font-size: ptr(14);
            display: none;
            ul {
                li {
                    margin: 0!important;
                    padding: ptr(10) 0;
                    box-shadow: none;
                    border: none;
                }
            }
            p {
                padding: 0 0 ptr(20) 0;
            }
        }
        &.open {
            .infoEventContainer {
                display: block;
            }
        }
        .infoUnlocked {
            .cal {
                color: $gris!important;
                .month {
                    font-size: ptr(12);
                }
                .day {
                    font-size: ptr(16);
                }
            }
        }
        .btnContent {
            button {
                &:last-child {
                    @media (max-width: 500px) {
                        margin-left: ptr(10)!important;
                    }
                }
            }
        }
        .btn-devinsider {
            &:first-child {
                background: #7a8288;
                border-color: #7a8288 !important;
            }
        }
    }
    .eventInfos {
        font-size: ptr(18);
        .infoEvent {
            padding-top: 0;
        }
    }
    .btnContent {
        @extend .d-flex,
        .align-items-stretch,
        .flex-column;
        .btn {
            margin: 0!important;
        }
        .edit {
            background-color: #E4E6EB;
            font-size: ptr(10);
            color: #57585A;
            margin-bottom: ptr(20);
            height: ptr(32);
            border-radius: ptr(3);
            width: ptr(122);
            position: relative;
            @extend .d-flex,
            .align-items-center,
            .justify-content-center;
            cursor: pointer;
            .collapseDiv {
                display: none;
                position: absolute;
                top: ptr(32);
                border: 1px solid #E9E9E9;
                color: #444444;
                width: 100%;
                background-color: #fff;
                font-size: ptr(14);
                a {
                    width: 100%;
                    display: block;
                    padding: ptr(5) ptr(10);
                    color: #444444;
                    border-bottom: 1px solid #E9E9E9;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
            &:hover {
                .collapseDiv {
                    display: block;
                }
            }
            @media (max-width: 500px) {
                margin-right: ptr(10);
            }
        }
    }
}