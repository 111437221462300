@import "../../../style.scss";
.iconImg {
    .color-svg {
        fill: $orange;
    }
}

.menuConversationchoice {
    &.nav-tabs {
        .nav-link {
            border-radius: 0;
        }
    }
}

.listLabelLink {
    li {
        &:first-child/*Product Marketing*/
        {
            .cls-2 {
                stroke: #444;
                fill: transparent !important;
                stroke-width: 2;
            }
            path {
                &.cls-1 {
                    fill: $orange !important;
                    stroke: $orange !important;
                }
            }
            .cls-4,
            .cls-3 {
                stroke-width: 2;
            }
        }
        &:nth-child(4),
        &:nth-child(6)/*Funding*/
        {
            .cls-1 {
                fill: $orange !important;
                stroke: $orange !important;
            }
            .cls-2 {
                stroke: #444;
                fill: #444!important;
                stroke-width: 0.2px;
            }
        }
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(10),
        &:nth-child(11)/*Bootstraping*/
        {
            .cls-2 {
                stroke-width: 0.5px;
            }
        }
        &:nth-child(9) {
            .cls-1 {
                stroke: $orange;
                stroke-width: 0.5px;
                fill: $orange !important;
            }
            .cls-2 {
                stroke: #444;
                stroke-width: 0.2px;
                fill: #444 !important;
            }
        }
        a {
            &:hover {
                svg {
                    transition: all 500ms;
                    path {
                        fill: $gris-claire;
                        &.color-svg {
                            fill: $gris;
                        }
                    }
                }
            }
        }
    }
    .cls-1 {
        stroke: #444;
        stroke-width: 0.2px;
        fill: #444 !important;
    }
    .cls-2 {
        stroke: $orange;
        stroke-width: 2px;
        fill: $orange !important;
    }
    .cls-3 {
        fill: #e5e5e5;
        stroke-width: 0;
    }
}

.discussionCategory {
    margin: 0;
    padding: ptr(60) 0;
    @media (max-width: 768px) {
        padding: 0;
    }
    svg {
        height: ptr(52);
    }
    .cls-1 {
        stroke-width: 0;
    }
    li {
        &:first-child {
            .cls-1 {
                stroke: #444;
                stroke-width: 0.2px;
                fill: #444 !important;
            }
            .cls-2 {
                stroke: $orange;
                stroke-width: 0.5px;
                fill: $orange !important;
            }
        }
        &:nth-child(2) {
            .cls-1 {
                stroke-width: 0.2px;
            }
        }
        &:nth-child(3) {
            .cls-1 {
                stroke: #444;
                fill: #444;
                stroke-width: 1px;
            }
            .cls-2 {
                stroke-width: 2.5px;
                stroke: $orange;
            }
        }
        &:nth-child(4) {
            .cls-1 {
                fill: #444;
                stroke: #444;
            }
            .cls-3,
            .cls-2 {
                fill: $orange;
                stroke-width: 0;
                stroke: $orange;
            }
        }
        &:nth-child(5) {
            .cls-1 {
                fill: transparent;
                stroke-width: 2px;
            }
            .cls-2 {
                fill: transparent;
                stroke-width: 2px;
            }
        }
        &:nth-child(6) {
            .cls-1 {
                fill: transparent;
                stroke-width: 2px;
            }
            .cls-2 {
                fill: transparent;
                stroke-width: 2px;
            }
        }
    }
    .cls-2 {
        stroke-width: 0.5px;
    }
}

.forumCommunity {
    padding: ptr(50) 0 0 0;
    .block {
        border-top: none;
    }
}

.community2Col {
    @media(max-width: 768px) {
        flex-direction: column;
    }
}

.rightCommunityForum {
    width: ptr(754);
    margin: 0;
    padding-right: ptr(16);
    .topic {
        background: #e8e8e8 !important;
        color: #333 !important;
    }
    .titlePost {
        display: block;
    }
    &.favorite {
        width: 100%;
        .containerCategory {
            width: ptr(1020);
            margin: 0 auto;
        }
        h2.title {
            font-weight: 600;
            font-size: ptr(20);
            color: #444444;
            margin: 0;
            padding: ptr(16) 0;
        }
    }
    .block {
        margin: 0;
        padding: 0 0 ptr(80) 0;
        border-top: none;
        h2.title {
            color: #444444;
            font-weight: 600;
            font-size: ptr(20);
            margin: 0 0 ptr(10) 0;
            padding: 0 0 ptr(15) 0;
            border-bottom: 2px solid $orange;
            a {
                display: inline-block;
                margin: 0 ptr(10);
                color: #757575;
                &:hover,
                &.active {
                    color: $gris;
                }
            }
            &.uppercase {
                text-transform: uppercase;
            }
            &.withLink {
                white-space: nowrap;
                select {
                    display: none;
                }
                @media(max-width: 600px) {
                    a {
                        display: none;
                    }
                    select {
                        display: inline-block;
                        margin: 0 0 0 ptr(5);
                        border: 1px solid #757575;
                        appearance: none;
                        width: ptr(200);
                        font-size: ptr(16);
                        height: ptr(40);
                        padding: 0 ptr(10);
                        border-radius: 3px;
                        background: url(../../../resources/images/select.svg) center right 10px no-repeat;
                        background-size: ptr(10) auto;
                    }
                }
            }
        }
    }
     .blockOfList li{


        position: relative;
    }

    .blockOfList, .listBlockright{
         .pinned-item{
            position: absolute;
            top: -10px;
            width: 30px;
            right: 0;
        }
    }

    .listBlockright {

        .pinned-item{
            position: absolute;
            top: -12px;
            width: 30px;
            right: -3px;
        }
        li {
            @extend .d-flex;
            position: relative;
            border-bottom: 1px solid #cacaca;
            .itemBlock {
                width: 30%;
                padding: ptr(20) ptr(10);
                &:first-child {
                    width: 50%;
                    padding-right: ptr(30);
                }
                &:last-child {
                    width: 20%;
                }
                .time {
                    color: $bleu;
                    display: block;
                }
                .authorlink {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }
            &.title {
                text-transform: uppercase;
                font-weight: bold;
                font-size: ptr(16);
                color: $gris;
                border-bottom: none;
                .itemBlock {
                    padding-top: 0;
                }
            }
            .txtLabel {
                h3.title {
                    font-size: ptr(16);
                    span[class *="icon-"] {
                        color: #67b346;
                        display: inline-block;
                        margin-right: ptr(5);
                    }
                }
                &.withIcon {
                    @extend .d-flex,
                    .align-items-center;
                }
            }
            &.linkLi {
                border-bottom: none;
            }
            @media (max-width: 600px) {
                &.title {
                    display: none!important;
                }
                flex-direction: column;
                .itemBlock {
                    width: 100%!important;
                    padding: ptr(10) 0 !important;
                    &:nth-child(2n+2) {
                        display: none!important;
                    }
                    .time,
                    .authorlink {
                        display: inline-block!important;
                    }
                }
            }
        }
        &.categDiscussion {
            .itemBlock {
                @extend .d-flex,
                .flex-column,
                .justify-content-center;
            }
            h3.title {
                @extend .d-flex,
                .align-items-center;
                span[class *="icon-"] {
                    background: $orange;
                    border-radius: ptr(300);
                    width: ptr(42);
                    height: ptr(42);
                    color: #ffffff!important;
                    @extend .d-flex,
                    .align-items-center,
                    .justify-content-center;
                }
            }
            .contributionItem {
                text-align: center;
                padding-right: ptr(80);
            }
        }
    }
    @media (max-width: 1024px) {
        width: 75%;
        &.favorite {
            .containerCategory {
                width: 100%;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100% !important;
    }
}

.titlePost {
    font-weight: 600;
}

.leftCommunityForum {
    width: ptr(408);
    background-color: #eeeeee;
    padding: ptr(20) ptr(16);
    margin-bottom: ptr(80);
    .titlePost {
        display: block;
    }
    .block {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #969696;
        color: #4f4f4f;
        font-size: ptr(16);
        padding-bottom: ptr(36);
        margin-bottom: ptr(16);
        h2.title {
            font-size: ptr(20);
            font-weight: 600;
            color: #444;
            margin: 0;
            padding: ptr(20) 0;
        }
        a {
            color: $bleu;
        }
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
        &:first-child {
            h2.title {
                padding-top: 0;
            }
        }
    }
    @media (max-width: 1024px) {
        width: 25%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
}

.listOfLinkHandy {
    margin: 0;
    padding: 0;
    li {
        padding-bottom: ptr(5);
    }
}

.pinned-item{

    position: absolute;
    width: 30px;
    top:-11px;
    right: -2px;
}

.listBlockright .itemBlock.txtLabel.infoFeed{

    width: 50%
    
}