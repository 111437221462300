@import "../../../../style.scss";
.custom-control.orangecheck {
    input:checked~.custom-control-label:before {
        background-color: $orange;
        border-color: $orange;
    }
    label {
        &:before {
            border-radius: 0;
        }
    }
    &.mini {
        label {
            font-weight: normal!important;
        }
    }
}

.categories__menu,
.zonetext__menu {
    z-index: 60;
}

.zonetext__menu {
    z-index: 2!important;
}

.categories__indicator-separator,
.zonetext__indicator-separator {
    display: none;
}

.categories__dropdown-indicator,
.zonetext__dropdown-indicator {
    background: url(../../../../resources/images/selectbg.svg) center right 5px no-repeat;
    background-size: 20px auto;
    width: ptr(30);
    svg {
        display: none;
    }
}

.rightCommunityForum.oneCateg.postContainer {
    .labelLeft {
        padding: 0;
        background-color: transparent;
        width: ptr(300);
        .block {
            margin: 0;
            padding: 0 0 ptr(30) 0;
            label {
                margin: 0;
            }
            .custom-control-label {
                font-weight: bold;
                font-size: ptr(16);
            }
        }
        h2.title {
            border-bottom: 1px solid;
        }
        .zonetext__dropdown-indicator {
            background-image: none;
            display: none;
        }
        .form-control {
            margin: ptr(10) 0;
            input[type=text] {
                width: 100%;
                height: 100%;
                border: none!important;
                font-weight: 600;
                font-size: ptr(12);
                color: $gris;
            }
            &.no-border {
                border: none;
                padding: 0;
            }
        }
        .instructionlabel {
            font-weight: 600;
            font-size: ptr(14);
        }
    }
    .containerCategory {
        h2.title {
            font-weight: 600;
            font-size: ptr(20);
            margin: 0 0 ptr(20) 0;
            padding: 0 0 ptr(16) 0;
            border-bottom: 2px solid $orange;
        }
        input[type=text] {
            margin: 0 0 ptr(20) 0;
        }
        .formForEmail {
            button {
                margin: 0 ptr(16) 0 0;
            }
        }
    }
}

.listOfTopic {
    .topic {
        font-size: ptr(13);
        text-transform: lowercase;
        background-color: #e8e8e8;
        color: #444444;
        font-weight: 600;
        border-radius: ptr(30);
        display: inline-block;
        margin: 0 ptr(5) ptr(5) 0;
        padding: 0 ptr(5);
        cursor: pointer;
        text-transform: capitalize;
        &.active {
            background-color: #444444 !important;
            color: #fff !important;
        }
    }
}

.selectPartner {
    margin-top: ptr(20);
    .partnerProgram__placeholder {
        padding: 0;
        &::before {
            display: none;
        }
    }
    .partnerProgram__option--is-selected {
        background-color: #e7e7e7;
        color: #333333;
    }
    .partnerProgram__option--is-focused {
        background-color: #ffffff;
    }
}

.searchPartner {
    .searchWord {
        width: 100%;
    }
    .keyword {
        border-color: #a7a7a7 !important;
        color: #808080;
        &:focus-visible {
            border: 1px solid #a7a7a7;
        }
    }
}

.partnerProgram__placeholder {
    padding-left: ptr(25);
    &::before {
        content: "\2315";
        font-size: ptr(39);
        position: absolute;
        top: ptr(-17);
        left: ptr(-6);
        transform: rotate(-90deg);
        color: #a7a7a7;
    }
}

.partnerProgram__control {
    border-color: #a7a7a7 !important;
    box-shadow: 0 0 1px #a7a7a7 !important;
    &:hover {
        border-color: #a7a7a7 !important;
        box-shadow: 0 0 1px #a7a7a7 !important;
    }
}

.listOfChoice {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #e7e7e7;
    margin-bottom: 8px;
    margin-top: 8px;
    position: relative;
    width: ptr(250);
    z-index: 1;
    box-sizing: border-box;
    ul {
        li {
            padding: 8px 12px;
            width: 100%;
            cursor: pointer;
            &:hover {
                background: #e8e8e8;
            }
        }
    }
}

.lab {
    margin: initial !important;
    padding-left: 0 !important;
}