@import "../../../../style.scss";
.contentTopic {
    width: ptr(1020);
    margin: 0 auto;
    padding: ptr(30) 0;
}

.topicMaster {
    width: 100%;
    position: relative;
    background-color: #fbf9f9;
    border: 1px solid #c4c4c4;
    padding: ptr(20) ptr(16);
    @extend .d-flex,
    .align-items-stretch;
    margin: 0 0 ptr(40) 0;
    &.reply {
        background-color: #fff;
        margin: ptr(20) 0;
    }
    &.reperefixed {
        .format-wysiwyg {
            img {
                width: ptr(550) !important;
                display: block;
                margin: 0 auto;
            }
        }
    }
}

.badgeSubject {
    width: ptr(200);
    @extend .d-flex,
    .flex-column,
    .justify-content-between;
    .btm {
        font-size: ptr(14);
    }
    .infoAffiliated {
        .post {
            white-space: inherit;
            line-height: 1;
            padding: ptr(8) 0;
            width: 100%;
        }
    }
}

.badgePoster {
    @extend .d-flex,
    .align-items-start;
    .withPost {
        width: ptr(55);
        .imageAffiliatedcompany {
            width: ptr(50);
            height: ptr(50);
            border-radius: ptr(100);
            top: 0;
            left: 2px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .nameOfPost {
        margin: ptr(3);
        width: ptr(60);
        height: ptr(60);
    }
    .infoAffiliated {
        color: $gris;
        font-size: ptr(14);
        padding: 0 0 0 ptr(10);
        b {
            font-weight: 600;
        }
        &.replyContent {
            align-self: center;
            font-size: ptr(16);
            font-weight: 600;
            color: $bleu;
        }
    }
}

.topicSubject {
    margin: 0;
    padding: 0 0 0 ptr(20);
    color: #333333;
    position: relative;
    width: calc(100% - 200px);
    .title {
        h2 {
            color: #333333;
            font-weight: 600;
            font-size: ptr(17);
        }
        .date {
            font-size: ptr(17);
            color: #333333;
        }
        margin: 0;
        padding: 0 0 ptr(20) 0;
    }
    .topic {
        p {
            margin: 0;
            padding: 0 0 ptr(25) 0;
        }
    }
    .deleted,
    .best-answer {
        width: 80%;
        border-radius: 3px;
        background-color: #ffdfdf;
        color: #9e3838;
        padding: ptr(20);
        margin: ptr(20) 0;
        position: relative;
        padding: ptr(16) ptr(16) ptr(16) ptr(80);
        .alerticon {
            position: absolute;
            left: ptr(30);
            top: 30%;
            font-size: ptr(30);
        }
        h3.title {
            margin: 0;
            padding: 0 0 ptr(10) 0;
            font-size: ptr(18);
            font-weight: 600;
        }
    }
    .best-answer {
        background-color: #dfffed;
        color: #318940;
        span.alerticon {
            font-size: ptr(16);
            top: 36%;
        }
    }
    .categContainer {
        font-size: ptr(15);
        color: #6d6d6d;
        .subCategory {
            span {
                font-size: ptr(13);
                font-weight: 600;
                color: #444444;
            }
        }
    }
    .replyContent {
        margin: 0;
        padding: ptr(20) 0 0 0;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
}

.menudrop {
    position: absolute;
    top: 0;
    right: 0;
    .dropdown {
        height: ptr(30);
    }
    .dropdown-toggle,
    .dropdown-toggle:hover,
    .dropdown-toggle.active {
        border: none !important;
        box-shadow: none !important;
        font-size: ptr(9);
        width: ptr(40);
        height: ptr(10);
        margin: 0;
        padding: 0;
        background-color: transparent !important;
        border: none;
        color: #8a8a8d;
        transform: rotate(90deg);
        &:after {
            display: none;
        }
    }
    .dropdown-menu.show {
        border-color: #6d6d6d;
        border-radius: 0;
        padding: 0;
        .dropdown-item {
            border-bottom: 1px solid #6d6d6d;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .show {
        .dropdown-toggle {
            background-color: transparent !important;
            color: #8a8a8d !important;
        }
    }
}

.likeAndBtn {
    @extend .d-flex,
    .justify-content-between,
    .align-items-center;
    padding: ptr(20) 0 0 0;
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
    .btnContentSubject {
        @media (max-width: 768px) {
            margin: 20px 0;
        }
    }
}

.like {
    @extend .d-flex,
    .align-items-center;
    font-weight: 600;
    color: $gris;
    a {
        width: ptr(43);
        height: ptr(43);
        border: 1.5px solid #6d6d6d;
        color: #8a8a8d;
        @extend .d-flex,
        .flex-column,
        .justify-content-center,
        .align-items-center;
        font-size: ptr(10);
        border-radius: 3px;
        margin: 0 ptr(10) 0 0;
        text-transform: uppercase;
        &:hover,
        &.active {
            color: #fff!important;
            background-color: #6d6d6d;
        }
    }
    b {
        font-weight: 600;
        color: $orange;
        margin-right: ptr(5);
    }
}

.btnContentSubject {
    @extend .d-flex;
    a {
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
        border-radius: 3px;
        height: ptr(32);
        min-width: ptr(120);
        color: #fff!important;
        font-size: ptr(14);
        font-weight: 600;
        background-color: #8a8a8d;
        margin: 0 ptr(10) 0 0;
        &.addFav {
            background-color: $orange;
        }
        &:hover {
            color: #fff;
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
        }
        &.bestAnswer {
            background-color: #51bb21;
        }
    }
}

.replyTopic {
    margin: 0;
    padding: ptr(20) 0;
    border-top: 2px solid #c4c4c4;
    h2.title {
        font-weight: 600;
        font-size: ptr(17);
        text-transform: uppercase;
        color: #333333;
    }
}

.nbrReplyandPagination {
    @extend .d-flex,
    .justify-content-between;
}

.replyOfReply {
    padding-left: ptr(90);
    position: relative;
    min-height: ptr(60);
    .contentReplyofreply {
        display: none;
    }
    .hideReply {
        position: absolute;
        top: ptr(10);
        left: ptr(20);
        color: $gris;
        background-size: 9.5px auto;
        padding: 0 0 0 ptr(15);
        display: none;
        &:after {
            width: ptr(15);
            height: ptr(15);
            position: absolute;
            top: ptr(2);
            left: ptr(-5);
            background: transparent;
            border: 1px solid $gris;
            content: "";
            border-radius: 2px;
        }
        &:before {
            background: $gris;
            height: 2px;
            width: 9px;
            border-radius: 2px;
            content: "";
            position: absolute;
            top: 9px;
            left: ptr(-2);
        }
    }
    .realclick {
        z-index: 2;
        position: absolute;
        width: 400px;
        height: ptr(60);
        background-color: transparent;
    }
    .openChild {
        z-index: 1;
        position: absolute;
        left: ptr(90);
        top: 0;
        background: url(../../../../resources/images/replyHide.svg) center left no-repeat;
        background-size: 25px auto;
        padding: 0 0 0 ptr(30);
    }
    .seeMoreList {
        color: $orange;
        font-weight: 600;
        font-size: ptr(14);
        display: none;
    }
    &.open {
        .seeMoreList {
            display: inline-block;
        }
        .contentReplyofreply {
            display: block;
        }
        .openChild,
        .realclick {
            display: none;
        }
        .hideReply {
            display: block;
        }
    }
}

.nameOfReply {
    display: inline-block;
    font-weight: 600;
    font-size: ptr(14);
}

.discussion-fixed {
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: ptr(-300);
    transition: all 500ms;
    z-index: 30;
    padding: ptr(16) 0;
    box-shadow: 0 6px 6px rgba($color: #000000, $alpha: 0.16);
    .container {
        position: relative;
        @extend .d-flex,
        .align-items-center;
        div.title {
            padding: 0;
            max-width: 65%;
            @media (max-width: 768px) {
                max-width: 100%;
            }
        }
        .likeAndBtn {
            justify-self: flex-end;
            padding: 0;
            position: absolute;
            right: 0;
            @media (max-width: 768px) {
                position: relative;
                flex-wrap: wrap;
            }
        }
        div.like {
            padding-right: ptr(16);
        }
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    &.showDiscussionFixed {
        top: ptr(58);
        @media (max-width: 768px) {
            top: 0;
        }
    }
}

ul.separateElement.classifiedAds li .leftItem {
    width: 100%;
    padding-right: 0;
    .infoAffiliated {
        h3.title {
            color: $gris;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            font-size: ptr(14);
        }
        .post {
            font-size: ptr(14);
            font-weight: normal;
        }
    }
}

ul.separateElement.classifiedAds li .leftItem .imageAffiliatedcompany,
.rightCommunityForum .feedList.pressRelease .imageAffiliatedcompany {
    width: ptr(52);
    height: ptr(52);
    border: 1px solid #e4e4e4!important;
    border-radius: 0;
    &.empty {
        background: #fff url(../../../../resources/images/defaultCompany.png) center no-repeat;
        background-size: 30px auto;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.topicMaster,
.replyOfReply,
.replyContentUser {
    .withPost {
        width: ptr(60);
        height: ptr(60);
        .imageAffiliatedcompany {
            width: ptr(60);
            height: ptr(60);
            left: 0;
        }
        .nameOfPost {
            height: ptr(70);
        }
    }
}

.discussion-fixed {
    .withPost {
        width: ptr(50);
        height: ptr(50);
        .imageAffiliatedcompany {
            width: ptr(50);
            height: ptr(50);
            left: 0;
        }
        .nameOfPost {
            height: ptr(60);
        }
    }
}

@media (max-width: 1024px) {
    .contentTopic {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .topicMaster {
        flex-direction: column;
        .btm {
            padding: ptr(16) 0;
        }
    }
    .topicSubject {
        padding: ptr(16) 0;
    }
    .realclick,
    .openChild,
    .hideReply {
        display: none;
    }
    .replyOfReply {
        padding: 0 0 0 ptr(16) !important;
        .contentReplyofreply {
            display: block !important;
        }
    }
}

.showpopOverPerso {
    position: relative;
    display: inline-block;
    .popoverPerso {
        width: ptr(450);
        height: auto;
        position: absolute;
        z-index: 60;
        left: 0;
        display: none;
        top: 0;
        padding: ptr(25) 0 0 0;
        .popovoverContent {
            width: 100%;
            height: auto;
            min-height: ptr(200);
            background-color: #fff;
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
            border: 1px solid #bdbdbd;
            position: relative;
            color: $gris;
            font-size: ptr(15);
            p {
                padding: ptr(16);
            }
            .pressRelease {
                background-color: #eeeeee;
                width: calc(100% + 32px);
                margin: 0 ptr(-16) ptr(-16);
                padding: ptr(16);
                h4.title {
                    font-weight: 600;
                    font-size: ptr(16);
                    padding-bottom: ptr(16);
                    margin: 0;
                }
                .listPress {
                    li {
                        @extend .justify-content-between;
                        margin: 0;
                        padding: 0 0 ptr(15) 0;
                        border: none;
                        font-size: ptr(14);
                        .name {
                            width: 60%;
                            font-weight: 600;
                        }
                        p {
                            width: 25%;
                        }
                        &.nextPrev {
                            @extend .justify-content-start;
                            color: #444444;
                            span {
                                padding: 0 ptr(10);
                            }
                            a {
                                color: #444444;
                                &.inactive {
                                    color: #838f9b;
                                }
                            }
                        }
                    }
                }
            }
            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                border-bottom: 10px solid #bdbdbd;
                left: 29px;
                top: -10.75px;
            }
            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                border-bottom: 10px solid #ffffff;
                left: 29px;
                top: -10px;
            }
            .infoAbout {
                width: 100%;
                @extend .d-flex,
                .align-items-center;
                padding-bottom: ptr(20);
                margin-bottom: ptr(20);
                border-bottom: 1px solid #e8e8e8;
                text-align: left;
                padding: ptr(16);
                .infoFeed {
                    color: $gris;
                }
            }
            .others {
                font-weight: 600;
                background: #eeeeee;
                padding: ptr(16);
                h6 {
                    font-weight: 700;
                }
                .d-flex {
                    justify-content: space-between;
                    margin-bottom: ptr(15);
                    .info {
                        width: ptr(290);
                    }
                }
                .nextPrev {
                    a {
                        color: inherit !important;
                        &.inactive {
                            color: darkgray !important;
                            cursor: auto;
                        }
                    }
                }
            }
        }
        .nextPrev {
            padding-left: 0;
        }
    }
    p {
        text-align: left;
    }
    &:hover {
        .popoverPerso {
            display: block;
        }
    }
    .imageAffiliatedcompany {
        width: ptr(52);
        height: ptr(52);
        border: 1px solid #e4e4e4;
        border-radius: 0;
        margin: 0 ptr(10) 0 0;
        &.empty {
            background: #fff url(../../../../resources/images/defaultCompany.png) center no-repeat;
            background-size: 30px auto;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .cName {
        color: $bleu;
        cursor: pointer;
    }
    @media (max-width: 1024px) {
        &:hover {
            .popoverPerso {
                display: none;
            }
        }
    }
}