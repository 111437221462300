@import "../../../style.scss";
.firstIn {
    background-color: $bleu;
    color: #fff;
    padding: ptr(30) ptr(16) ptr(16);
    width: 100%;
    margin: ptr(30) 0 ptr(20) 0;
    b {
        font-weight: 600;
    }
    .btn {
        color: #fff;
        border: 1px solid #fff;
        border-radius: ptr(5);
        padding: 0 ptr(16);
        display: block;
        min-width: ptr(200);
        height: ptr(35);
        margin: ptr(16) 0 0 0;
    }
}

.benefitContent {
    @extend .d-flex,
    .justify-content-between;
    .fauxText {
        width: 48%;
    }
}

.listSquareItem {
    &.resource {
        &.grisbg {
            a.empty {
                .typeDoc {
                    background-color: #C1C1C1;
                    background-image: none;
                }
            }
        }
    }
}

.companyInvestorbtnctt {
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
}

.contentLeftInvestor {
    position: relative;
}

.addElement {
    width: 100%;
    border: 1.5px dotted #C1C1C1;
    height: ptr(50);
    @extend .d-flex,
    .align-items-center,
    .justify-content-center;
    color: #939393;
    margin: ptr(16) 0;
    span {
        display: block;
        padding: 0 0 0 ptr(20);
        background: url(../../../resources/images/plus-solid.svg) center left no-repeat;
        background-size: ptr(12) auto;
    }
    &:hover {
        color: #939393;
        border-color: #939393;
    }
}

.imageCtt {
    width: ptr(200);
    height: ptr(100);
    position: relative;
    margin: 0 0 ptr(20) 0;
    padding: ptr(5);
    border: 1px solid #C1C1C1;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .removeImg {
        position: absolute;
        width: ptr(30);
        height: ptr(30);
        top: ptr(5);
        right: ptr(5);
        background-color: $gris;
        border-radius: ptr(30);
        color: #fff;
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
    }
}

.disposition {
    @extend .d-flex,
    .flex-wrap;
    padding: ptr(20) 0;
    p {
        width: 100%;
        margin: 0;
        padding: 0 0 ptr(16) 0;
        font-weight: 600;
        font-size: ptr(14);
    }
    a {
        width: ptr(176);
        height: ptr(72);
        background: top center no-repeat;
        background-size: 100% auto;
        border: 1.5px solid transparent;
        margin: 0 ptr(5) ptr(10) 0;
        &.photoText {
            background-image: url(../../../resources/images/disp-imgtextright.svg);
        }
        &.textPhoto {
            background-image: url(../../../resources/images/disp-imgtextleft.svg);
        }
        &.textOnly {
            background-image: url(../../../resources/images/disp-text.svg);
        }
        &.textCol {
            background-image: url(../../../resources/images/disp-text2col.svg);
        }
        &.upTextPhoto {
            background-image: url(../../../resources/images/disp-imgtextdown.svg);
            height: ptr(104);
        }
        &.upPhotoText {
            background-image: url(../../../resources/images/disp-imgtextup.svg);
            height: ptr(104);
        }
        &.active {
            border-color: $orange;
        }
    }
}

.rdw-editor-main {
    border: 1px solid #F1F1F1;
    border-top: none;
}

.removeElement,
.removeElement:hover {
    display: table;
    margin: ptr(16) auto;
    color: #e21010;
    font-weight: 600;
}

.elementText {
    padding: ptr(10);
    border: 1.5px dotted #C1C1C1;
}

.dispositionShow {
    display: flex;
    flex-wrap: wrap;
}

.elementDisposition {
    display: flex;
    padding: 0 0 ptr(30) 0;
    &.justText,
    &.textUpImg,
    &.imgUpText {
        flex-direction: column;
    }
    &.justText {
        .imageElement {
            display: none
        }
    }
    &.text2Col {
        .textElement {
            p {
                column-count: 2;
            }
        }
        .imageElement {
            display: none;
        }
    }
    &.textUpImg {
        flex-direction: column-reverse;
        .imageElement {
            img {
                margin-top: ptr(20);
                width: 100%;
                height: ptr(400);
            }
        }
    }
    &.imgUpText {
        .imageElement {
            img {
                margin-bottom: ptr(20);
                width: 100%;
                height: ptr(400);
            }
        }
    }
    &.imgFirsttext,
    &.textFirstimg {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .imageElement,
        .textElement {
            width: 48%;
        }
        .imageElement {
            height: ptr(224);
        }
    }
    &.textFirstimg {
        flex-direction: row-reverse;
    }
    @media (max-width: 768px) {
        &.text2Col {
            p {
                column-count: 1;
            }
        }
        &.imgFirsttext,
        &.textFirstimg {
            flex-direction: column;
            .imageElement,
            .textElement {
                width: 100%;
            }
        }
    }
}

.imageElement {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.textElement {
    h3.title {
        font-size: ptr(24);
        font-weight: 600;
        text-align: left!important;
        margin: 0;
        padding: 0 0 ptr(30) 0;
    }
    line-height: 2;
    p {
        margin: 0;
        padding: 0;
    }
}

.container.cont2 {
    position: relative;
}

.contentRightInvestor {
    transition: all 500ms;
    &.fixContentRight {
        position: fixed;
        top: ptr(100);
        right: calc((100vw - 1105px)/2);
        background-color: #fff;
        z-index: 89;
    }
    @media(max-width: 1024px) {
        &,
        &.fixContentRight {
            position: static;
            right: 0!important;
            bottom: 0!important;
            top: auto!important;
            z-index: 20;
            background: #fff;
            width: 100%;
            margin: ptr(20) 0;
        }
    }
}

.fixedSecondHeader {
    .active {
        &.about {
            border-color: transparent;
        }
    }
}