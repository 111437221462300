a {
    color: $bleu;
    transition: all 500ms;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: $bleu;
    }
    &.linkgetstarted {
        border: 1px solid #a4a4a4;
        border-radius: ptr(7.3);
        background: #fff;
        font-size: ptr(16);
        font-weight: 600;
        color: $gris;
        width: 100%;
        margin: 0 0 ptr(20) 0;
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .align-items-center;
        min-height: ptr(51);
        &.large {
            height: ptr(100);
        }
        &.gris {
            background-color: $gris-disabled;
        }
        &:hover,
        &.active {
            background: #163a64;
            color: #fff;
            border-color: #163a64;
        }
    }
    &.link-back {
        color: $gris-claire;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
    }
    &.link-add-photo {
        border: 2px solid $orange;
        padding: 0.5rem;
        font-weight: 600;
        width: ptr(205);
        height: ptr(45);
        text-align: center;
        color: $orange;
        cursor: pointer;
        @media (max-width: 500px) {
            width: 100%;
        }
    }
    &.upload-photo {
        width: ptr(117);
        height: ptr(117);
        border-radius: ptr(300);
        border: 1px solid #c4c4c4;
        background: url(../../images/photo.svg) center top 25px no-repeat;
        background-size: 50%;
        overflow: hidden;
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .align-items-center;
        &.noBackground {
            background-image: none;
        }
        img {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            object-fit: cover;
        }
        &.square {
            border-radius: 0;
        }
    }
    &.skip {
        color: $orange;
        margin: ptr(24) auto;
        display: inline-block;
        &.rightgris {
            color: $gris;
            float: right;
            margin: ptr(10);
        }
    }
    &.signIn {
        text-transform: uppercase;
        color: $orange;
        text-transform: uppercase;
        font-size: ptr(16);
        font-weight: 600;
        padding: 0.25rem 1rem;
        border-radius: ptr(3);
        margin: 0 0 0 1rem;
    }
    &.getstarted {
        @extend a.signIn;
        background: #fff;
    }
    &.getStartedLink {
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .align-items-center;
        /* height: ptr(62);
        width: ptr(165); */
        font-weight: 600;
/*         font-size: ptr(18);
 */        
        font-size: 26px;
        width: ptr(255);
         padding: 15px;
        border-radius: ptr(4);
    }
}

.choiceInvestorCategory {
    display: block;
    width: 48%;
    height: ptr(38);
    color: $gris;
    background-color: #f2f2f3;
    @extend .d-flex,
    .align-items-center,
    .justify-content-center;
    border-radius: ptr(3);
    &:hover,
    &.active {
        color: #fff;
        background-color: $orange;
    }
}

.editDoneIsv {
    background-color: $bleu;
    color: #fff;
    font-size: ptr(17);
    border-radius: ptr(3);
    padding: ptr(10);
    width: ptr(230);
    text-align: center;
    &:hover {
        color: #fff;
    }
}

.editProfilbtn {
    background-color: #e7e7e7;
    border: 1px solid #cecece;
    font-size: ptr(16);
    height: ptr(28);
    min-width: ptr(32);
    color: $gris;
    margin: 0 ptr(10) 0 0;
    border-radius: ptr(3);
    @extend .d-flex,
    .align-items-center;
    span {
        margin: 0 ptr(3);
    }
}


/*Menu link*/

.menuHeader {
    .container {
        .navbar-nav {
            .nav-item {
                .underLine {
                    display: inline;
                    position: relative;
                    margin-bottom: -2px;
                    border-bottom: 2px solid transparent;
                    @media (max-width: 1024px) {
                        border: none;
                    }
                }
                &.show {
                    .nav-link {
                        color: #fff;
                        .underLine {
                            border-color: #fff;
                        }
                    }
                }
            }
            .nav-link:hover,
            .nav-link:focus {
                color: #fff;
            }
        }
        &.longMenu {
            /*position: relative;*/
            .rightMenulink,
            .leftMenulink {
                .dropdown {
                    position: static;
                    .dropdown-toggle {
                        &:after {
                            display: none;
                            @media (max-width: 1024px) {
                                display: inline;
                            }
                        }
                    }
                    .dropdown-menu {
                        width: 100%;
                        border-radius: 0;
                        border: 0;
                        top: -2px !important;
                        box-shadow: 0px 3px 3px 2px rgba($color: #000, $alpha: 0.2);
                        .titleMenu {
                            @include display-desktop-only("flex");
                            border-bottom: 3px solid $orange;
                            padding: ptr(4) ptr(16) ptr(12) ptr(16);
                            @extend .align-items-center;
                            height: ptr(50);
                            color: $gris;
                            .title {
                                font-size: ptr(24);
                                font-weight: 600;
                                padding: 0 ptr(16) 0 0;
                                border-right: 1px solid #969696;
                                cursor: pointer;
                            }
                            .labelMenu {
                                padding: 0 ptr(16);
                            }
                        }
                        .listLinkNav {
                            @extend .d-flex;
                            @extend .flex-wrap;
                            height: ptr(168);
                            .dropdown-item {
                                &:active,
                                &.active {
                                    background: transparent;
                                }
                                color: $gris !important;
                                width: calc(25% - 20px);
                                margin: ptr(10);
                                white-space: initial;
                                border-radius: 3px;
                                .linkItem {
                                    font-size: ptr(17);
                                    font-weight: 600;
                                    padding: 0 0 ptr(20) 0;
                                    display: block;
                                }
                                p {
                                    margin: 0;
                                    padding: 0;
                                    font-size: ptr(15);
                                    @include display-desktop-only("block");
                                }
                                &:last-child {}
                            }
                            @media (max-width: 1024px) {
                                flex-direction: column;
                                height: auto;
                                .dropdown-item {
                                    width: 100%;
                                    margin: 0;
                                    padding: ptr(10) ptr(15);
                                    border-bottom: 1px solid #e8e8e8;
                                    &:last-child {
                                        border-bottom: none;
                                    }
                                    .linkItem {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                        .titleMenu,
                        .listLinkNav {
                            .container {
                                align-items: center !important;
                                justify-content: start !important;
                            }
                        }
                        @media (max-width: 1024px) {
                            padding: 0;
                            box-shadow: none;
                            position: static !important;
                            transform: none !important;
                            height: auto;
                            .titleMenu,
                            .listLinkNav {
                                .container {
                                    padding: 0 !important;
                                }
                            }
                        }
                    }
                    &.show {
                        .dropdown-toggle {
                            &:before {
                                position: absolute;
                                display: block;
                                bottom: 0;
                                content: "";
                                border-top: 0;
                                border-right: 10px solid transparent;
                                border-bottom: 8px solid;
                                border-left: 10px solid transparent;
                                color: #fff;
                                right: calc(50% - 10px);
                                @media (max-width: 1024px) {
                                    display: none;
                                }
                            }
                            &:after {
                                @media (max-width: 1024px) {
                                    border-top: 0;
                                    border-bottom: ptr(4.8) solid;
                                }
                            }
                        }
                    }
                }
                &.notShow {
                    @extend .d-none;
                }
                &.contentLinkconnected {
                    color: #fff;
                    @extend .align-items-center;
                    .dropdown {
                        position: relative;
                        width: ptr(60);
                        height: ptr(47);
                        &.profilMenu {
                            @extend .d-flex;
                            @extend .align-items-center;
                            @extend .justify-content-center;
                            .dropdown-toggle {
                                margin: 0 !important;
                                width: ptr(40);
                                height: ptr(40);
                                background: #fff url(../../images/user.svg) center no-repeat;
                                background-size: 130% auto;
                                border-radius: ptr(40);
                                overflow: hidden;
                                .profilHeaderImage {
                                    width: 110%;
                                    height: 110%;
                                    @extend .d-flex,
                                    .align-items-center,
                                    .justify-content-center;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        margin: 0;
                                        padding: 0;
                                        object-fit: cover;
                                    }
                                    @media (max-width: 1024px) {
                                        margin: 0;
                                        justify-content: start!important;
                                        img {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        @media (max-width: 1024px) {
                            width: 100%;
                            height: auto;
                            flex-direction: column;
                            &.profilMenu {
                                .dropdown-menu {
                                    width: 100%;
                                    .dropdown-item {
                                        font-size: ptr(17);
                                        font-weight: 600;
                                        padding: ptr(15) ptr(10);
                                        border-color: #e8e8e8;
                                    }
                                }
                                .dropdown-toggle {
                                    width: 100%;
                                    background: center left no-repeat;
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                    [class*="icon-"] {
                        font-size: ptr(20);
                    }
                    .nav-link {
                        margin: 0;
                        color: #fff;
                        width: ptr(60);
                        height: ptr(47);
                        @extend .d-flex;
                        @extend .justify-content-center;
                        &:before {
                            display: none !important;
                        }
                        span {
                            &[class*="icon-"] {
                                @include display-desktop-only(block);
                            }
                            &.labelLink {
                                @include display-tablet-mobile(block);
                            }
                        }
                        &.notificationForMobile {
                            @include display-tablet-mobile(flex);
                        }
                        @media (max-width: 1024px) {
                            width: 100%;
                            padding: 0.625rem 1rem;
                            color: #cccccc;
                            justify-content: start !important;
                        }
                    }
                    .dropdown-menu {
                        left: calc(50% - 75px);
                        right: auto;
                        box-shadow: none;
                        border: 1px solid #c7c7c7;
                        padding: 0;
                        width: ptr(150);
                        background: #fff;
                        top: ptr(48)!important;
                        &[aria-labelledby="notificationLink"] {
                            width: ptr(300);
                            left: calc(50% - 150px);
                            .title {
                                padding: ptr(10);
                                text-align: center;
                                color: $gris;
                                font-size: ptr(14);
                                border-bottom: 1px solid #c7c7c7;
                            }
                            .dropdown-item {
                                white-space: normal;
                                font-size: ptr(12);
                                padding-left: ptr(40);
                                &.commentNotif {
                                    background: url(../../images/comment.svg) left 13px top 9px no-repeat;
                                    background-size: ptr(16) auto;
                                }
                                &:last-child {
                                    font-size: ptr(14);
                                    text-align: center;
                                    padding: ptr(5);
                                }
                            }
                        }
                        .dropdown-item {
                            border-bottom: 1px solid #c7c7c7;
                            color: $gris;
                            text-align: center;
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.joinHome {
    &.reseller {
        .getStartedLink {
            padding: ptr(5) ptr(30);
            color: #373737;
            background: #fff;
            border-radius: ptr(2);
            &:hover {
                background-color: rgba($color: #fff, $alpha: 0.5);
            }
        }
    }
}


/*end Menu link*/

.link-topic {
    cursor: pointer;
    background: $gris-disabled;
    width: ptr(165.9);
    height: ptr(165.9);
    border-radius: ptr(7.3);
    @extend .d-flex;
    @extend .align-items-center;
    @extend .flex-column;
    @extend .justify-content-center;
    text-transform: uppercase;
    font-size: ptr(14);
    font-weight: bold;
    color: #444444;
    margin: ptr(5);
    text-align: center;
    position: relative;
    .cls-3 {
        fill: #eee;
        stroke-width: 0.5px;
    }
    &:first-child {
        .cls-2 {
            stroke: #444;
            fill: transparent !important;
            stroke-width: 1.5;
        }
        path {
            &.cls-1 {
                fill: $orange !important;
                stroke: $orange !important;
            }
        }
        .cls-4,
        .cls-3 {
            stroke-width: 1.5;
        }
    }
    &:nth-child(4),
    &:nth-child(6)/*Funding*/
    {
        .cls-1 {
            fill: $orange !important;
            stroke: $orange !important;
        }
        .cls-2 {
            stroke: #444;
            fill: #444!important;
            stroke-width: 0.2px;
        }
    }
    &:nth-child(7) {
        .cls-3 {
            fill: $orange;
            stroke: $orange;
            stroke-width: 0;
        }
    }
    svg {
        width: auto;
        height: ptr(72);
        fill: #444;
        path {
            &.color-svg {
                fill: $orange;
            }
        }
        margin-bottom: ptr(14);
    }
    &:hover,
    &.active {
        background-color: #444;
        color: #fff;
        svg,
        .color-svg {
            fill: #ffffff;
        }
        .cls-1,
        .cls-2,
        .cls-3,
        .cls-4,
        .color-svg {
            fill: #fff;
            stroke: #fff;
            stroke-width: 0px;
        }
        &:nth-child(4),
        &:nth-child(6) {
            .cls-2,
            .cls-1 {
                fill: #fff !important;
                stroke: #fff !important;
            }
        }
        &:first-child {
            .cls-1,
            .cls-2 {
                fill: #fff !important;
                stroke: #fff !important;
            }
        }
    }
    &.active {
        &:before {
            position: absolute;
            content: "";
            right: ptr(10);
            top: ptr(10);
            background: url(../../images/checked-active.svg);
            width: ptr(24);
            height: ptr(24);
            background-size: 100% auto;
        }
    }
    @media (max-width: 500px) {
        width: 9.5rem;
        height: 9.5rem;
    }
}

.firstMenu {
    a {
        color: #cccccc;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0;
        position: relative;
        &.active,
        &:hover {
            color: #fff;
        }
        .badge {
            position: absolute;
            top: ptr(-6);
            right: ptr(-28);
            color: #30d64e;
            border: 1.125px solid #30d64e;
            border-radius: ptr(2);
            font-size: ptr(10);
            text-transform: none;
        }
    }
}

.line-2 {
    .nav-link {
        height: ptr(58);
        @extend .d-flex;
        @extend .align-items-center;
        color: $gris;
    }
    .divisionLink {
        .nav-link {
            padding-left: 0;
            min-width: ptr(142);
            position: relative;
            font-size: ptr(24);
            font-weight: 300;
            margin: 0 ptr(30) 0 0;
            &:after {
                content: "";
                right: 0;
                top: ptr(8);
                width: ptr(1);
                height: calc(100% - 16px);
                background-color: #969696;
                position: absolute;
            }
        }
    }
    .startPost {
        .nav-link {
            background-color: $orange;
            padding: 0.5rem ptr(53);
            color: #fff;
            border-radius: ptr(3);
            height: ptr(30);
        }
    }
    @media (max-width: 1024px) {
        .nav-item.divisionLink,
        .nav-item.startPost,
        .nav-item {
            .nav-link {
                background-color: #1f1f1f;
                color: #969696;
                margin: 0;
                width: 100%;
                position: relative;
                padding: ptr(15);
                border-bottom: 1px solid #2e2e2e;
                font-size: 16px;
                font-weight: 600;
                height: auto;
                &:after {
                    width: 0;
                    height: 0;
                }
            }
            .dropdown-menu {
                .dropdown-item {
                    border-bottom: 1px solid $gris-disabled;
                    padding: ptr(10) ptr(24);
                }
            }
        }
    }
}

.contentNavConversation {
    .nav-tabs {
        .nav-link {
            width: 100%;
            border: none;
            background-color: #f4f3f2;
            font-size: ptr(18);
            font-weight: normal;
            text-transform: uppercase;
            text-align: center;
            height: ptr(55);
            color: #757575;
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;
            &.active {
                background-color: #e5e5e5;
                color: #444444;
            }
            @media (max-width: 500px) {
                font-size: ptr(14);
                font-weight: 600;
            }
        }
    }
    .listLabelLink {
        li {
            a {
                @extend .d-flex;
                @extend .flex-column;
                @extend .align-items-center;
                @extend .justify-content-center;
                color: #444444;
                font-size: ptr(14);
                font-weight: 600;
                width: 100%;
                height: ptr(130);
                position: relative;
                span {
                    position: absolute;
                    bottom: ptr(15);
                    text-transform: lowercase;
                }
                span::first-line {
                    text-transform: capitalize;
                }
                svg {
                    fill: #444444;
                    height: ptr(52);
                    .color-svg {
                        fill: $orange;
                    }
                }
                &.exepssionLink {
                    svg {
                        width: ptr(70);
                        height: auto;
                    }
                }
            }
        }
    }
    .linkInterne {
        a {
            margin: 0;
            padding: ptr(5) ptr(10);
            text-align: center;
            font-size: ptr(13);
            color: #444444;
            &:last-child {
                color: $orange;
            }
        }
    }
}

.listsoftwarevendor {
    .learnMore {
        /*width: ptr(104);
        height: ptr(30);
        background-color: $orange;
        border: 1px solid $orange;*/
        font-size: ptr(15);
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .align-items-center;
        margin: ptr(30) auto ptr(10) auto;
        color: $orange;
        font-weight: 600;
        /*border-radius: ptr(30);*/
        img {
            width: ptr(25);
            margin-left: ptr(5);
        }
        &:hover {
            background-color: #FFFFFF;
            color: $orange;
        }
    }
}

.inclinereseller {
    .getStartedLink {
        background: $orange;
        color: #fff;
        height: ptr(44);
        min-width: ptr(176);
        padding: 0 ptr(10);
    }
}

.settings {
    .contentTabSettings {
        @media (max-width: 1024px) {}
        @media (max-width: 800px) {
            flex-direction: column;
            nav.nav-tabs {
                flex-direction: row!important;
                flex-wrap: nowrap;
                width: 100%;
                border-bottom: 2px solid $bleu;
                overflow-x: auto;
                a {
                    border-left: 0!important;
                    border-bottom: 2px;
                    margin-right: ptr(16)!important;
                    white-space: nowrap;
                }
            }
        }
    }
}

.createCompanyLink {
    background-color: $orange;
    color: #fff;
    font-size: ptr(16);
    font-weight: normal;
    padding: ptr(8) ptr(16);
    border-radius: ptr(2);
    &:hover {
        color: #fff;
        background-color: rgba($color: $orange, $alpha: 0.5);
    }
}

.columnOfCompany {
    .maxi-column {
        .nav {
            .onMyprofil {
                .nav-link {
                    white-space: nowrap;
                    span {
                        margin-right: ptr(2);
                        display: inline-block;
                    }
                    font-size: ptr(16);
                    padding: ptr(2) ptr(3);
                    margin: ptr(7) ptr(6) 0 0;
                    border-radius: ptr(5);
                    background-color: #e7e7e7;
                    border: 1px solid #cecece;
                    &:hover {
                        background-color: rgba($color: #e7e7e7, $alpha: .5);
                    }
                }
            }
        }
    }
}

.editBackground,
.editPictureProfil {
    position: absolute;
    width: 100%;
    background-color: rgba($color: #000, $alpha: 0.6);
    height: ptr(40);
    left: 0;
    top: 0;
    padding: ptr(10);
    color: #fff;
    text-align: center;
    span {
        display: inline-block;
        margin: 0 ptr(10) 0 0;
    }
    &:hover {
        color: #fff;
        background-color: #000;
    }
}

.editPictureProfil {
    /*position: static;*/
    display: block;
    height: ptr(30);
    padding-top: ptr(5);
}

.linkEditLeftCompany {
    position: absolute;
    top: ptr(10);
    right: ptr(6);
    color: $bleu;
    span {
        margin: 0 0 0 ptr(8);
        border: 1px solid $bleu;
        width: ptr(31);
        height: ptr(31);
        padding: ptr(5);
    }
    &:hover {
        span {
            background-color: $bleu;
            color: #fff;
        }
    }
}


/*.financialcontainer {
  .bord{
    .linkEditLeftCompany{
      color: $bleu;
      top: ptr(5);
      right: ptr(5);
    }
  }
}*/

.linkAffiliated {
    display: inline-block;
    margin: 0 0 0 ptr(20);
    color: $gris;
    &.approveInvitation {
        color: $orange;
    }
}

ul.pagination.pagination-container {
    margin: 0;
    padding: 0;
    border: 1px solid #b3b3b3;
    border-radius: 0;
    li.pagination-item {
        margin: 0;
        padding: 0;
        height: ptr(32);
        width: ptr(32);
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
        margin: 0;
        border-left: 1px solid #b3b3b3;
        border-radius: 0;
        &:first-child {
            border-left: none;
        }
        a {
            border: none;
        }
    }
}