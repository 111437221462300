@import "../../../style.scss";
.classified {
    margin: 0;
    padding: ptr(25) 0;
    .filterFindIsv {
        box-shadow: none;
    }
    &.details {
        .container {
            @extend .d-flex,
            .justify-content-between;
        }
        .leftSection {
            width: ptr(315);
            border-radius: 8px;
            border: 1px solid #f0f0f0;
            padding: ptr(16);
            box-shadow: 0 0 5px rgba($color: #000000, $alpha: .06);
            .block {
                border-top: none;
                border-bottom: 1px solid #c4c4c4;
                h2.title {
                    font-weight: 600;
                    font-size: ptr(15);
                    color: $gris;
                    margin: 0;
                    padding: 0 0 ptr(10) 0;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        .rightSection {
            width: ptr(810);
            border-radius: 8px;
            border: 1px solid #f0f0f0;
            padding: ptr(16);
            box-shadow: 0 0 5px rgba($color: #000000, $alpha: .06);
            h2.title {
                color: #444444;
                font-size: ptr(24);
                font-weight: 600;
                margin: 0;
                padding: 0 0 ptr(10) 0;
            }
            .techno {
                display: block;
                font-size: ptr(14);
                margin: 0;
                padding: 0 0 ptr(10) 0;
            }
            .linkOfDetails {
                @extend .d-flex,
                .align-items-center;
                a {
                    font-weight: 600;
                    color: #444444;
                    background: #e4e6eb;
                    border-radius: 3px;
                    height: ptr(32);
                    min-width: ptr(32);
                    width: auto;
                    @extend .d-flex,
                    .align-items-center,
                    .justify-content-center;
                    margin: 0 ptr(16) 0 0;
                    &:hover {
                        background-color: #444444;
                        color: #fff;
                    }
                }
            }
            &.newAdsPost {
                box-shadow: none;
                border: none;
                padding: 0;
            }
        }
    }
    .container {
        &.minHeightwin {
            max-width: 100%;
            width: 100%;
            @media (max-width: 1700px) {
                padding: 0 0 0 ptr(370);
            }
            @media (max-width: 1024px) {
                width: 100%;
                padding: 0;
            }
            @media (max-width: 600px) {
                padding: 0;
            }
        }
    }
}

.detailsAds {
    margin: 0;
    padding: ptr(30) 0;
    h3.title {
        font-size: ptr(16);
        font-weight: 600;
        margin: 0;
        padding: 0 0 ptr(20) 0;
    }
    p {
        margin: 0;
        padding: 0 0 ptr(20);
    }
    ul {
        margin: 0;
        padding: 0 0 ptr(20) ptr(20);
    }
}

ul.listItemAds {
    margin: 0;
    padding: 0 0 0 ptr(16);
    li {
        padding: 0 0 ptr(10) 0;
        a {
            color: $orange;
        }
    }
}

.explore-ads,
.freshfinds {
    margin: ptr(25) 0;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 5px 5px rgba($color: #000000, $alpha: .06);
    padding: ptr(30) ptr(25);
    width: 100%;
    height: auto;
    display: block;
    h2.title {
        font-size: ptr(24);
        font-weight: 600;
        color: $gris;
        margin: 0;
        padding: 0 0 ptr(25);
    }
    @media (max-width: 500px) {
        padding: ptr(16);
    }
}

h2.outsideFinds {
    font-size: ptr(24);
    font-weight: 600;
    color: $gris;
    margin: 0;
    padding: 0;
}

.freshfinds {
    margin-top: ptr(30);
}

ul.listOfAds {
    @extend .d-flex;
    padding: 0 ptr(20);
    li {
        margin: 0;
        padding: 0 ptr(100) 0 0;
        a {
            color: #57585a;
            font-size: ptr(16);
            @extend .d-flex,
            .flex-column,
            .align-items-center;
            span[class*="icon-"] {
                color: $orange;
                font-size: ptr(60);
                margin: 0 0 ptr(16) 0;
            }
        }
    }
    @media (max-width: 768px) {
        justify-content: space-between;
        li {
            padding: 0;
        }
    }
    @media (max-width: 500px) {
        flex-wrap: wrap;
        padding: 0;
        li {
            width: 49.5%;
            text-align: center;
            margin: 0 0 ptr(20) 0;
        }
    }
}

.listFresh,
.infoCompanyAds {
    @extend .d-flex,
    .justify-content-start,
    .flex-wrap;
    .linkView {
        width: 100%;
        display: block;
        .viewAll {
            display: table;
        }
    }
    .item {
        border-radius: ptr(8);
        border: 1px solid #e9e9e9;
        padding: ptr(16);
        width: calc(25% - 16px);
        margin: 0 ptr(21) ptr(21) 0;
        font-size: ptr(14);
        position: relative;
        .addFavorisAds {
            position: absolute;
            top: ptr(16);
            right: ptr(16);
            color: #57585a;
            &:hover {
                color: #57585a;
            }
        }
        .imgclassified {
            width: ptr(85);
            height: ptr(85);
            border: 1px solid #e9e9e9;
            &.empty {
                background: url(../../../resources/images/emptyAds.svg) center no-repeat;
                background-size: 50% auto;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .infoAds {
            width: 100%;
            min-height: ptr(130);
        }
        h3.title {
            font-weight: 600;
            font-size: ptr(16);
            padding: ptr(10) 0 0 0;
            margin: 0;
            cursor: pointer;
        }
        .descriptionAds {
            width: 100%;
            margin: ptr(10) 0;
            padding: 0;
            display: -webkit-box;
            max-width: 200px;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: ptr(12);
            color: #7a8288;
        }
        .name {
            .role {
                display: inline-block;
                font-weight: 600;
                color: $orange;
                padding: 0 0 0 ptr(15);
                background: url(../../../resources/images/role.svg) left 5px center no-repeat;
                background-size: auto 12px;
            }
        }
        .topic {
            font-size: ptr(13);
            font-weight: 600;
            color: #444444;
            background: #e8e8e8;
            border-radius: 12px;
            padding: ptr(2) ptr(10);
            margin: 0 ptr(10) ptr(5) 0;
            display: inline-block;
            cursor: pointer;
        }
        .infoAds {
            font-size: ptr(14);
        }
        .otherInfo {
            font-size: ptr(12);
        }
        &:nth-child(4n+4) {
            margin-right: 0;
        }
        @media (max-width: 768px) {
            width: 32%;
            margin-right: 1.995%;
            &:nth-child(4n+4) {
                margin-right: 1.995%;
            }
            &:nth-child(3n+3) {
                margin-right: 0;
            }
        }
        @media (max-width: 500px) {
            width: 100%;
            margin-right: 0;
            &:nth-child(4n+4) {
                margin-right: 0;
            }
            &:nth-child(3n+3) {
                margin-right: 0;
            }
        }
    }
}

.block.infoProgramContainer {
    border-top: none;
}

.classifiedAdsScroll {
    &.listOfIsv {
        .scrollContainer {
            min-height: 20vh;
            height: calc(100vh - 170px);
            border-radius: ptr(8);
            padding: ptr(16);
            box-shadow: 0 0 5px rgba($color: #000000, $alpha: .12);
            @media (max-width: 1024px) {
                min-height: auto!important;
                overflow: auto;
                height: auto;
                max-height: auto;
            }
        }
        .listFresh {
            .item {
                width: calc(33.3% - 10.7px);
                margin: 0 ptr(16) ptr(16) 0;
                &:nth-child(3n+3) {
                    margin-right: 0;
                }
                @media (max-width: 600px) {
                    width: 100%;
                    margin: ptr(10) auto;
                }
            }
        }
        @media (max-width: 1024px) {
            width: 100%;
            padding: ptr(16);
        }
    }
}

.contentBorder.forAds.forPitch {
    width: ptr(720);
    a.choicePost {
        width: ptr(176);
        height: ptr(176);
        font-size: ptr(14);
        text-transform: none;
    }
    p {
        margin: 0;
        padding: 0 0 ptr(30) 0
    }
    label {
        font-weight: normal;
        padding: 0 0 ptr(20) 0;
    }
}

.infoCompanyAds {
    @extend .d-flex;
    .imgclassified {
        width: ptr(85);
        height: ptr(85);
        background-size: 50% auto;
        overflow: hidden;
        border: 1px solid #c4c4c4;
        &.empty {
            background: #c4c4c4 url(../../../resources/images/company-white.png) center no-repeat;
            background-size: 40% auto;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .infoAds {
        margin: 0;
        padding: 0 0 0 ptr(20);
        font-size: ptr(14);
        color: #57585a;
        h3.title {
            font-weight: 600;
            font-size: ptr(16);
            color: #444444;
            padding: ptr(3) 0;
            margin: 0;
        }
        .role {
            color: $orange;
            font-weight: 600;
            padding: 0 0 0 ptr(12);
            background: url(../../../resources/images/role.svg) center left no-repeat;
            background-size: auto ptr(12);
        }
    }
}

.newAdsPost {
    h2.title {
        font-size: ptr(20);
        font-weight: 600;
        color: $gris;
        border-bottom: 2px solid $orange;
        margin: 0 0 ptr(16) 0;
        padding: 0 0 ptr(20) 0;
    }
    .titlePost,
    .titlePost:focus-visible {
        width: 100%;
        margin: ptr(20) 0;
        border-radius: 3px;
        border: 1px solid #dcdada;
        height: ptr(38);
        color: #333333;
        padding: 0 ptr(16);
        &::placeholder {
            color: #333333;
        }
    }
}

.slidecheck {
    @extend .d-flex,
    .align-items-center,
    .flex-wrap;
    font-weight: 600;
    font-size: ptr(14);
    .custom-checkbox {
        margin: 0;
        padding: 0 0 ptr(20) 0;
        width: 100%;
        label.custom-control-label {
            min-width: ptr(200);
            padding: 0 ptr(40) 0 0;
            cursor: pointer;
            &:before {
                right: 0;
                left: auto;
                width: ptr(30);
                height: ptr(20);
                background-color: #c4c4c4!important;
                border: none!important;
                border-radius: 30px;
                top: 0;
            }
            &:after {
                background: #ffffff;
                background-image: none;
                width: ptr(16);
                height: ptr(16);
                border-radius: 30px;
                right: ptr(12);
                left: auto;
                top: ptr(2);
            }
        }
        input:checked~label {
            &:before {
                background-color: #4fbe79!important;
            }
            &:after {
                right: ptr(2);
            }
        }
    }
}

.postAdcomment {
    font-size: .875rem;
    color: #939393;
    margin: ptr(20) ptr(10) ptr(20) ptr(10);
}

.messageMarketPlace {
    margin: ptr(20) ptr(10);
    border: 1px solid #c4c4c4;
    padding: ptr(16);
}

.replyContentUser {
    margin: 0 0 ptr(30) 0;
    border: 1px solid #c4c4c4;
    padding: ptr(16);
    .formForEmail {
        padding: ptr(20) 0 ptr(10) 0;
    }
    @extend .d-flex,
    .align-items-start;
    .badgePoster {
        width: ptr(200);
        padding: 0 ptr(20) 0 0;
        @media (max-width: 768px) {
            margin-bottom: ptr(30);
        }
    }
    .editorContent {
        width: calc(100% - 200px);
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
}