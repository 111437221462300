.joinConversation {
    h1.title {
        font-size: ptr(32);
        font-weight: 600;
        width: ptr(1200);
        margin: 0 auto;
        padding: ptr(0) ptr(0) ptr(20) ptr(0);
        text-align: center;
        @media(max-width: 1200px) {
            width: 100%;
            padding: 0 ptr(15) ptr(20) ptr(15);
        }
    }
}

.loginScreen {
    .title {
        text-align: center;
        font-weight: normal;
        font-size: ptr(28);
        margin: 0;
        padding: 0 0 ptr(16) 0;
    }
}

.textforjoin,
.getintouch {
    .title {
        font-size: ptr(22);
        font-weight: 600;
        color: $gris;
        padding: 0 0 ptr(24) 0;
        @media(max-width: 768px) {
            font-size: ptr(22)!important;
        }
    }
}

.contentCheckboxsolution {
    .title {
        font-size: ptr(15);
        color: $gris;
        width: 100%;
        background: #f2f2f3;
        padding: ptr(10) ptr(16);
        margin: 0 0 ptr(20) 0;
        border-radius: ptr(1);
    }
}

.aboutProfil {
    .title {
        margin: 0;
        padding: 0 0 ptr(10) 0;
        font-size: ptr(24);
        font-weight: 600;
        color: $bleu;
        @extend .d-flex,
        .align-items-center;
    }
}

.contactInfo,
.companyInfo {
    .title {
        border-bottom: 1px solid #C1C1C1;
        font-weight: 600;
        font-size: ptr(20);
        padding: 0 0 ptr(16) 0;
        margin: 0 0 ptr(16) 0;
    }
    .subtitle {
        font-size: ptr(16);
        margin: ptr(15) 0 ptr(16) 0;
        font-weight: 600;
    }
    .infoAffiliated {
        h3.title {
            color: $bleu;
            font-size: ptr(15);
            font-weight: 600;
            border: none;
            margin: 0;
            padding: 0 0 ptr(5) 0;
            line-height: 1;
        }
    }
}

.boxEditProfil {
    .title {
        font-size: ptr(20);
        font-weight: 600;
        border: 0;
        margin: 0;
        padding: 0 0 ptr(16) 0;
    }
}

.infoMyIsv {
    .title {
        font-size: ptr(20);
        font-weight: 600;
        margin: 0;
        padding: 0 0 ptr(16) 0;
    }
    h3.title {
        font-size: ptr(16);
        padding: 0 0 ptr(5) 0;
    }
}

.formInvestor {
    .title {
        font-size: ptr(28);
        font-weight: 600;
        color: $gris;
        text-align: center;
        span {
            font-size: ptr(18);
            font-weight: normal;
            display: block;
            padding: ptr(16) 0 0 0;
        }
    }
}

.contentRightsettings {
    h1.title {
        font-weight: 600;
        font-size: ptr(20);
        margin: 0 0 ptr(20) 0;
        padding: 0 0 ptr(16) 0;
        border-bottom: 2px solid $orange;
    }
    h2.title {
        font-weight: 600;
        font-size: ptr(17);
        margin: 0;
        padding: 0 0 ptr(10) 0;
        position: relative;
        a {
            font-weight: normal;
            font-size: ptr(16);
            position: absolute;
            top: 0;
            right: 0;
            color: #666666;
            @media (max-width: 600px) {
                color: $bleu
            }
        }
        &.withValue {
            width: 100%;
            font-size: ptr(16);
            @extend .d-flex,
            .justify-content-between;
            .itemEdit {
                width: 50%;
                @extend .d-flex,
                .justify-content-end;
                &.loading {
                    position: relative;
                    &:before {
                        content: "Loading...";
                        font-weight: 300;
                        width: 100%;
                        text-align: right;
                        position: absolute;
                        @media (max-width: 600px) {
                            text-align: left;
                        }
                    }
                    div[class *="custom-"] {
                        display: none;
                    }
                }
            }
        }
        &.emailEdit {
            @extend .d-flex;
            @media (max-width: 600px) {
                flex-direction: column;
            }
        }
        @media (max-width: 600px) {
            &.withValue {
                flex-direction: column;
                .itemEdit {
                    margin: 0;
                    padding: ptr(16) 0;
                    width: 100%;
                    justify-content: start!important;
                    * {
                        transition: all 500ms;
                    }
                    .custom-radio {
                        margin: 0 ptr(30) 0 0;
                    }
                }
            }
        }
    }
}

.titleSettings {
    margin: 0;
    padding: 0 0 ptr(15) 0;
}

.companyPhotoName {
    h2.title {
        font-weight: bold;
        color: $gris;
        font-size: ptr(20);
        margin: 0;
        padding: ptr(10) 0 0 0;
        text-align: center;
    }
}

@media(max-width:768px) {
    /* h1.title{
        font-size: 2.5rem!important;
    }*/
}

.joinHome {
    &.reseller,
    &.invest,
    &.about {
        .title {
            color: #fff;
            font-weight: bold;
            font-size: ptr(46);
            margin: 0;
            padding: 0 0 ptr(50) 0;
        }
    }
}

.inclinereseller {
    font-size: ptr(18);
    h1.title {
        font-size: ptr(30);
        font-weight: 600;
        color: #373737;
        margin: 0;
        padding: 0 0 ptr(30) 0;
    }
}

.headerpopupCompany {
    h2.title {
        font-weight: bold;
        font-size: ptr(18);
        margin: 0;
        padding: ptr(5) ptr(10) ptr(10);
        text-align: center;
    }
}

.instruction {
    font-size: ptr(15);
    text-align: center;
    h2.title {
        color: $gris;
        font-weight: bold;
        font-size: ptr(16);
        text-align: center;
        padding-bottom: ptr(20);
    }
}

section.createCompany {
    .listOfProgram {
        .list {
            h2.title {
                font-weight: normal;
                font-size: ptr(15);
                margin: 0;
                padding: 0 0 ptr(15) ptr(15);
                b {
                    font-weight: bold;
                }
            }
        }
    }
}

.containerLeftItem {
    h2.title {
        font-size: ptr(15);
        font-weight: 600;
    }
}

.infoAffiliated {
    h3.title,
    h4.title {
        font-weight: 600;
        font-size: ptr(14);
        color: $bleu;
        margin: 0;
    }
    .post,
    .adress {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .post {
        width: 100%;
        @media (max-width: 767px) {
            width: ptr(125);
        }
    }
}

.finalpopup {
    h3.title {
        font-weight: bold;
        font-size: ptr(20);
        margin: 0;
        padding: 0 0 ptr(20) 0;
    }
}

.invitationSent {
    h3.title {
        font-weight: 600;
        font-size: ptr(16);
    }
}