.container-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-round {
  padding: 3rem 0px 0px 1rem;
  width: 5.8125rem;
  height: auto;
  box-sizing: border-box;
}
.form-login {
  display: flex;
  flex-direction: column;
  width: 350px;
  justify-content: space-evenly;
}
.input {
  display: block;
  width: auto;
  height: calc(1em + 0.75rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.error-login {
  background-color: #f8d7da;
  text-align: center;
  font-family: "Source Sans Pro";
  outline: none;
  border: none;
}

.input:focus {
  border-color: #f16627;
}
.email {
  margin: 1rem 0px;
}
.password {
  width: 100%;
  margin: 0px;
  padding-right: 0rem;
  padding-left: 0.75rem;
}

.welcome {
  font-size: 1.75rem;
  line-height: 1;
  margin: 0;
  padding: 1rem 0rem 1.5rem 0px;
  color: #4f4f4f;
  font-family: "Source Sans Pro";
}
.lbl-show {
  position: absolute;
  top: 2px;
  bottom: 20px;
  right: 0.002rem;
  color: #0078b1;
  font-weight: 600;
  cursor: pointer;
}

.form-password {
  position: relative;
  width: auto;
  background-color: yellow;
  margin-right: 0.75rem;
}

.check-box-conatiner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: auto;
  align-items: center;
}
.small {
  padding: 0px 0px 2px;
}
.forgot {
  flex: 1;
  justify-self: flex-start;
  color: #f16627;
  font-family: "Source Sans Pro";
  cursor: pointer;
}
.lbl {
  font-family: "Source Sans Pro";
}
.checkBoxLabel {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  flex: 1.2;
  padding: 1rem 0px;
}
.btn {
  height: 2.75rem;
  font-size: 1.0625rem;
  font-weight: 600;
  border-radius: 5px;
  outline: none;
  color: white;
  font-family: "Source Sans Pro";
  cursor: pointer;
}
.form-btn {
  display: flex;
  flex-direction: column;
  padding: 1rem 0px;
  height: 4rem;
  justify-content: space-between;
}
.btn-signin {
  background-color: #f16627;
  border-color: #f16627;
  box-shadow: none;
  border-width: 0px;
}
.btn-linkdean {
  background-color: #0078b1;
  border-width: 0px;
}
.create-acount {
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
}
.lbl-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.getStarted {
  color: #1897c8;
  font-size: 1rem;
  transition: all 500ms;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}
