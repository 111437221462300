@import "../../../../style.scss";
.rightCommunityForum.topContainer{
    width: ptr(1024);
    margin: 0 auto;
    padding: 0;
    .withLink{
        margin: ptr(40) 0!important;
        .nav-link{
            padding: 0;
        }
    }
    h3.title{
        text-transform: uppercase;
        font-weight: 600;
        font-size: ptr(16);
        margin: 0;
        padding: ptr(10) 0 ptr(20) 0;
        color: #757575;
        
    }
}
.timeRange{
    @extend .d-flex, .align-items-center;
    font-size: ptr(14);
    overflow-x: scroll;
    margin: ptr(16) 0;
    p{
        margin: 0;
        font-weight: 600;
        padding: 0 ptr(30) 0 0;
    }
    a{
        border-radius: ptr(1);
        border: 1px solid $gris;
        color: $gris;
        padding: ptr(5) ptr(20);
        margin: 0 ptr(20) 0 0;
        &:hover, &.active{
            background-color: $gris;
            color: #fff;
        }
    }
}
.itemRank{
    width: 100%;
    border: 1px solid #c4c4c4;
    margin: 0 0 ptr(16) 0;
    @extend .d-flex, .align-items-center;
    height: ptr(80);
    overflow: hidden;
    padding: 0;
    .rang{
        width: ptr(80);
        height: ptr(80);
        background-color: $orange;
        color: #fff;
        font-weight: 600;
        @extend .d-flex, .align-items-center, .justify-content-center;
        font-size: ptr(15);
    }
    .infoRank{
        @extend .d-flex, .align-items-center;
        padding: ptr(10) ptr(16) ptr(10);
        .name{
            margin: 0;
            color: $bleu;
            font-weight: 600;
            font-size: ptr(16);
        }
        .like{
            font-weight: normal;
        }
    }
}
.allList{
    @extend .d-flex;
    width: 100%;
}
.rankAll{
    width: 50%;
}
.myrankContent{
    width: 50%;
    @extend .d-flex, .align-items-center, .justify-content-center;
}
.myrank{
    width: 80%;
    .itemRank{
        height: ptr(150);
        position: relative;
        overflow: visible;
        .rang{
            position: absolute;
            width: ptr(100);
            height: ptr(100);
            top: ptr(-50);
            left: ptr(10);
            padding: ptr(10);
            text-align: center;
            background-color: #dcdcdc;
            color: $gris;
        }
        .infoRank{
            margin: 0 auto;
            flex-direction: column;
            .imageAffiliatedcompany{
                width: ptr(60);
                height: ptr(60);
                margin: 0;
            }
            .nameAndLike{
                @extend .justify-content-center;
            }
            .like{
                width: 100%;
                justify-content: center;
            }
        }
    }
}
.otherList.row{
    margin: ptr(20) 0 0 0;
    padding: ptr(30) 0;
    border-top: 1px solid #c4c4c4;
    .itemRank{
        border: none;
        .rang{
            background-color: transparent;
            color: $gris;
            width:ptr(60);
        }
    }
}